import { ImagePreview } from "vant";
import { Toast } from "vant";
// 单独的 Toast 提示函数
export const showToast = (message, duration = 2000) => {
    Toast({
        message,
        duration, // 持续时间，默认 2000 毫秒
    });
};
// 显示加载状态的函数
export const showLoading = (message = "Loading...") => {
    Toast.loading({
        message,
        forbidClick: true,
        duration: 0,
        loadingType: "spinner" // 设置加载类型为转圈
    });
};
// 关闭加载状态的函数
export const hideLoading = () => {
    Toast.clear(); // 清除当前显示的 Toast，包括加载状态
};
export const getRandomAvatar = (identicon) => {
    if (identicon.children.length)
        return;
    const colors = ["#8473C6", "#DBBB9A", "#84BED4", "#6F99D2", "#9EDB90"];
    let data = [];
    for (let i = 0; i < 5; i++) {
        data.push([
            Math.floor(Math.random() * 2),
            Math.floor(Math.random() * 2),
            Math.floor(Math.random() * 2)
        ]);
    }
    data = data.map(function (d) {
        d.push(d[1], d[0]);
        return d;
    });
    identicon.innerHTML = "";
    const color = colors[Math.floor(Math.random() * colors.length)];
    data.forEach(function (d) {
        d.forEach(function (_d) {
            const el = document.createElement("div");
            el.setAttribute("data", _d.toString());
            el.style.background = color;
            identicon.appendChild(el);
        });
    });
};
export const getBeforeNowCount = (date) => {
    date = date.replace(/-/g, "/");
    // 苹果浏览器不支持replaceAll
    // TODO 苹果浏览器统计的时间和安卓不一样，有人知道欢迎提PR😊
    const diffDate = Date.parse(date);
    const now = Date.parse(new Date().toString());
    let diffDay = Math.abs(diffDate - now);
    diffDay = Math.floor(diffDay / (24 * 3600 * 1000));
    if (diffDay > 0) {
        return diffDay + "天前";
    }
    else {
        const diffHour = new Date(date).getHours();
        const nowHour = new Date().getHours();
        const diffed = Math.abs(diffHour - nowHour);
        if (diffed > 0)
            return diffed + "小时前";
        const diffSecond = new Date(date).getSeconds();
        const nowSecond = new Date().getSeconds();
        const diffedSecond = Math.abs(diffSecond - nowSecond);
        return diffedSecond + "秒前";
    }
};
export const showImg = (imgs, option) => {
    ImagePreview({
        images: imgs,
        startPosition: option ? option.startPosition || 0 : 0
    });
};
