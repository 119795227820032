import { defineComponent, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { showLoading, hideLoading, showToast } from "@/utils/utils"; // 导入工具函数

import { accountPage, pageUpi, updateQuota, getAppSettings } from "@/api/account";
import { getUserBalance } from "@/api/userInfo";
export default defineComponent({
  name: "UPIPage",

  setup() {
    const tools = ref([]); // 存储分页查询的工具列表

    const loading = ref(false); // 加载状态

    const finished = ref(false); // 是否加载完成

    const currentPage = ref(1); // 当前页数

    const pageSize = 10; // 每页显示数量

    const router = useRouter();
    const showQuotaDialog = ref(false);
    const quotaForm = reactive({
      orderAmount: "",
      orderNum: "" // 订单数量由后端返回，用户不可修改

    });
    const amountError = ref("");
    const maxOrderNum = ref(0); // 最大数量

    const orderNumError = ref("");
    const userQuota = ref(0); // 用户配额

    const currentTool = ref(null);
    const dialogStep = ref(1); // 控制弹窗的步骤
    // 点击 Edit 按钮时，将工具数据传递到 editAccount 页面

    const editAccount = tool => {
      router.push({
        path: "/accountList/editAccount",
        query: {
          id: tool.appLoginId,
          appCode: tool.appCode,
          appLoginId: tool.appLoginId,
          loginName: tool.loginName
        }
      });
    }; // 检查 UPI 账户状态


    const checkUpiStatus = async tool => {
      const upiResponse = await pageUpi({
        appLoginId: tool.appLoginId
      }); // 检查 records 数组

      if (upiResponse.data && Array.isArray(upiResponse.data.records)) {
        const validUpi = upiResponse.data.records.find(upi => upi.useCode === "1" // 查找 useCode 为 1 的记录
        );

        if (validUpi) {
          tool.hasUpi = true; // 用户有 UPI

          if (validUpi.checkCode == "1" || validUpi.checkCode == "2") {
            // 已验证或系统自动验证
            tool.tipsMessage = ""; // 清空提示信息
          } else {
            // checkCode 为 "0"，表示未验证
            tool.tipsMessage = "Your UPI account has not been verified yet"; // 提示未验证
          }
        } else {
          // 没有找到有效的 UPI 记录
          tool.tipsMessage = "You currently do not have a UPI account";
          tool.hasUpi = false; // 用户没有 UPI
        }
      } else {
        console.error("No valid UPI data found");
        tool.tipsMessage = "You currently do not have a UPI account";
        tool.hasUpi = false; // 用户没有 UPI
      }
    };

    const validateOrderNum = () => {
      if (parseInt(quotaForm.orderNum) > maxOrderNum.value) {
        orderNumError.value = `Order quantity cannot exceed ${maxOrderNum.value}`;
      } else {
        orderNumError.value = "";
      }
    }; // 加载工具数据


    const loadTools = async () => {
      if (loading.value || finished.value) return;
      showLoading("Loading accounts..."); // 显示加载中的 Toast

      loading.value = true;

      try {
        const response = await accountPage({
          current: currentPage.value,
          size: pageSize
        });

        if (response.code === 1) {
          // 循环 accountPage 的记录并检查 UPI 状态
          const newTools = await Promise.all(response.data.records.map(async tool => {
            // 调用 checkUpiStatus 方法检查工具的 UPI 状态
            await checkUpiStatus(tool);
            return tool;
          }));
          tools.value.push(...newTools);

          // 判断是否加载完成
          if (tools.value.length >= response.data.total) {
            finished.value = true; // 数据已全部加载，设置 finished 状态
          } else {
            currentPage.value++; // 增加页码以加载更多数据
          }
        } else {
          finished.value = true; // 如果没有数据或返回错误，标记为完成
        }
      } catch (error) {
        console.error("Error fetching accounts:", error);
        finished.value = true; // 请求失败也标记为完成，防止重复调用

        showToast("Error fetching accounts");
      } finally {
        loading.value = false; // 无论请求成功与否，都应关闭加载状态

        hideLoading(); // 请求完成后关闭加载中的 Toast
      }
    }; // 刷新工具数据


    const onRefresh = async () => {
      currentPage.value = 1;
      tools.value = [];
      finished.value = false;
      await loadTools();
    }; // 加载更多工具数据


    const onLoad = async () => {
      if (!finished.value) {
        await loadTools();
      }
    };

    const toDetail = path => {
      router.push(path);
    }; // 点击 Authorize 按钮时，将工具数据传递到授权页面


    const Authorize = tool => {
      // 构建目标路径，使用 tool.appCode 拼接路径地址
      const targetPath = `/accountList/selecttool/${tool.appCode}`; // 使用 router 跳转到目标路径，同时传递 appCode 和 appLoginId 参数

      router.push({
        path: targetPath,
        query: {
          appCode: tool.appCode,
          appLoginId: tool.appLoginId,
          authorize: "true" // 添加一个标识符，表明是重新授权

        }
      });
    }; // 修改 Quota 设置


    const changeQuota = async tool => {
      currentTool.value = tool; // 获取用户配额

      try {
        const balanceResponse = await getUserBalance();

        if (balanceResponse.code === 1) {
          userQuota.value = balanceResponse.data || 0;
        } else {
          showToast(`Error fetching user balance: ${balanceResponse.msg}`);
          return;
        }
      } catch (error) {
        console.error("Error fetching user balance:", error);
        showToast(error.message || "Error fetching user balance");
        return;
      } // 判断用户配额是否大于0


      if (userQuota.value <= 0) {
        showToast("Your quota is insufficient to set up orders");
        return;
      } // 初始化表单数据


      quotaForm.orderAmount = "";
      quotaForm.orderNum = "";
      amountError.value = "";
      orderNumError.value = "";
      dialogStep.value = 1; // 初始化为第一步

      showQuotaDialog.value = true;
    }; // 当派单金额改变时，调用后端接口获取订单数量


    const onOrderAmountChange = async () => {
      amountError.value = "";
      orderNumError.value = "";
      quotaForm.orderNum = "";
      const amount = parseInt(quotaForm.orderAmount);

      if (isNaN(amount) || amount <= 0 || !Number.isInteger(amount)) {
        amountError.value = `Amount must be a positive integer`;
        return;
      } // 调用后端接口获取订单数量


      try {
        const response = await getAppSettings({
          appLoginId: currentTool.value.appLoginId,
          amount: quotaForm.orderAmount
        });

        if (response.code === 1) {
          quotaForm.orderNum = response.data.count || "0";
          maxOrderNum.value = parseInt(response.data.count) || 0; // 设置最大值

          amountError.value = "";
        } else {
          showToast(response.msg);
          amountError.value = response.msg;
        }
      } catch (error) {
        console.error("Error calculating order quantity:", error);
        amountError.value = error.message || "Error calculating order quantity";
      }
    }; // 点击 Next 按钮


    const onNext = async () => {
      amountError.value = "";
      orderNumError.value = "";
      quotaForm.orderNum = ""; // 调用 onOrderAmountChange 方法

      await onOrderAmountChange(); // 如果没有错误，进入下一步

      if (!amountError.value) {
        dialogStep.value = 2;
      }
    }; // 取消 Quota 设置


    const onQuotaCancel = () => {
      showQuotaDialog.value = false;
      dialogStep.value = 1; // 重置为第一步
    }; // 确认 Quota 设置


    const onQuotaConfirm = async () => {
      amountError.value = "";
      orderNumError.value = "";
      const amount = parseInt(quotaForm.orderAmount);

      if (isNaN(amount) || amount <= 0 || !Number.isInteger(amount)) {
        amountError.value = `Amount must be a positive integer`;
        dialogStep.value = 1; // 返回第一步

        return;
      }

      const orderNum = parseInt(quotaForm.orderNum); //  if (isNaN(orderNum) || orderNum <= 0 || !Number.isInteger(orderNum)) {
      //   orderNumError.value = "Order quantity is invalid";
      //  return;
      //  }
      // 提交到服务器

      try {
        const response = await updateQuota({
          appLoginId: currentTool.value.appLoginId,
          amount: quotaForm.orderAmount,
          count: quotaForm.orderNum
        });

        if (response.code === 1) {
          showToast(response.msg);
          showQuotaDialog.value = false;
          dialogStep.value = 1; // 重置为第一步
          // 如有需要，更新工具对象中的数据
        } else {
          showToast(response.msg);
        }
      } catch (error) {
        console.error("Error updating quota settings:", error);
        showToast(error.msg || "Error updating quota settings");
      }
    }; // 初始化时调用接口获取工具列表


    onMounted(() => {
      loadTools();
    });
    return {
      tools,
      editAccount,
      toDetail,
      loading,
      finished,
      onRefresh,
      onLoad,
      Authorize,
      checkUpiStatus,
      changeQuota,
      showQuotaDialog,
      quotaForm,
      onQuotaCancel,
      onQuotaConfirm,
      onOrderAmountChange,
      dialogStep,
      onNext,
      amountError,
      orderNumError,
      userQuota,
      maxOrderNum,
      validateOrderNum
    };
  }

});